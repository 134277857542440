import React from 'react'
import {Link} from 'gatsby'

const Footer = () => {
  return (
    <footer className={'footer'}>
      <div className={'content has-text-centered'}>
        <p>
          © {new Date().getFullYear()} Dean Eckert
        </p>
        <p>
          <Link to={'/imprint'} >Impressum</Link>
        </p>
      </div>
    </footer>
  )
}

export default Footer