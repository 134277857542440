import React from 'react'
import './layout.scss'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'


interface Props {
  readonly title: string
  readonly children: React.ReactNode
}

const Layout: React.FC<Props> = ({title, children}) => (
  <div className={'page-wrap'}>
    <main>
      <Navbar />
      <div className={'content'}>
        {children}
      </div>
      <Footer />
    </main>
  </div>

)

export default Layout