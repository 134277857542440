import React from 'react'
import Layout from '../../layouts/layout'

const BlogPage = () => {
  return (
    <Layout title={`Blog 📝`}>
      <section className={'section'}>
        <h1 className={'title'}>Hier tut ich Krizel, ja 🧑‍🎨</h1>
      </section>
    </Layout>
  )
}

export default BlogPage
