import React from 'react'
import {Link} from 'gatsby'

const Navbar = () => {
  const [burgerActive, setBurgerActive] = React.useState(false)
  const toggleBurgerActive = () => setBurgerActive(!burgerActive)
  const burgerActiveClass = burgerActive ? 'is-active' : ''
  const devbuild = '_self' in React.createElement('div')
  const dropdown = <div className={'navbar-item has-dropdown is-hoverable'}>
    <a className={'navbar-link'}>
      Zeug
    </a>
    <div className={'navbar-dropdown'}>
      <Link className={'navbar-item'} to={'/tribaltools'}>
        TribalTools
      </Link>
    </div>
  </div>
  const blog = <Link className={'navbar-item'} to={'/blog'}>
    Blog
  </Link>
  return (
    <nav className={'navbar'} role={'navigation'}>
      <div className={'container'}>
        <div className='navbar-brand'>
          <Link className='navbar-item' to='/'>
            😾💻🎮
          </Link>

          <a role='button' className={`navbar-burger ${burgerActiveClass}`} aria-label='menu' aria-expanded='false'
             data-target='navbarBasic' onClick={toggleBurgerActive}>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
            <span aria-hidden='true'></span>
          </a>
        </div>
        <div id='navbarBasic' className={`navbar-menu ${burgerActiveClass}`}>
          <div className={'navbar-start'}>
            <Link className={'navbar-item'} to={'/'}>
              Home
            </Link>



            {devbuild ? blog : <div />}
            {dropdown}

          </div>

          <div className={'navbar-end'}>
            <div className={'navbar-item'}>
              <div className={'buttons'}>
                <a className={'button is-primary'} href={'https://twitter.com/eeedean'}>
                  <strong>Twitt0r</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
